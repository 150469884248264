import { useState, useEffect } from "react";
import axios from 'axios';
import Template from "../components/Template";
import Swal from 'sweetalert2'

function Home() {

    const [dateChk, setDateChk] = useState(() => {
        let d = new Date();
        let mm = d.getMonth() + 1;
        let dd = d.getDate();
        return '16022567';//dd + '' + mm + '' + d.getFullYear();
    });

    const [arrLottoCurrent, setArrLottoCurrent] = useState([]);
    const [arrLotto, setArrLotto] = useState([]);
    const [arrCheck, setArrCheck] = useState([]);
    const [inputLotto, setInputLotto] = useState('');
    const [arrTextReward, setArrTextReward] = useState(() => {
        return [
            { value: 1, text: 'ถูกรางวัลที่ 1 ค่ะ มูลค่า 6,000,000 บาท' },
            { value: 2, text: 'ถูกรางวัลเลขหน้า 3 ตัว ค่ะ มูลค่า 4,000 บาท' },
            { value: 3, text: 'ถูกรางวัลเลขหน้า 3 ตัว ค่ะ มูลค่า 4,000 บาท' },
            { value: 4, text: 'ถูกรางวัลเลขท้าย 3 ตัว ค่ะ มูลค่า 4,000 บาท' },
            { value: 5, text: 'ถูกรางวัลเลขท้าย 3 ตัว ค่ะ มูลค่า 4,000 บาท' },
            { value: 6, text: 'ถูกรางวัลเลขท้าย 2 ตัว ค่ะ มูลค่า 2,000 บาท' },
            { value: 7, text: 'ถูกรางวัลข้างเคียงรางวัลที่ 1 ค่ะ มูลค่า 100,000 บาท' },
            { value: 8, text: 'ถูกรางวัลข้างเคียงรางวัลที่ 1 ค่ะ มูลค่า 100,000 บาท' }
            // { value: 9, text: 'ถูกรางวัลที่ 2 ค่ะ มูลค่า 200,000 บาท' }
        ]
    });

    const [inputLength, setInputLength] = useState(false);

    const [textRewardTwo, setTextRewardTwo] = useState('ถูกรางวัลที่ 2 ค่ะ มูลค่า 200,000 บาท');
    const [textRewardThree, setTextRewardThree] = useState('ถูกรางวัลที่ 3 ค่ะ มูลค่า 80,000 บาท');
    const [textRewardFour, setTextRewardFour] = useState('ถูกรางวัลที่ 4 ค่ะ มูลค่า 40,000 บาท');
    const [textRewardFive, setTextRewardFive] = useState('ถูกรางวัลที่ 5 ค่ะ มูลค่า 20,000 บาท');

    const [strSplit, setStrSplit] = useState();


    useEffect(() => {
        fetchData();
    }, [])




    const fetchData = async () => {
        try {
            await axios.get('https://lotto-api-gqtl.onrender.com/api/getLotto/' + dateChk).then(res => {
                setArrLottoCurrent(res.data.articles);
                // setArrCheck(res.data.arr);
                console.log(arrLottoCurrent);



            }).catch(err => {
                throw err.response.data;
            })
        } catch (e) {
            console.log(e);
        }
    }


    const handleCheckDate = async () => {

        console.log(dateChk + '||' + inputLotto);
        console.log(inputLotto.length);
        if (inputLotto.length < 6 && inputLotto !== '') {
            setInputLength(true);
            // window.alert('test');

        } else {
            try {
                await axios.get('https://lotto-api-gqtl.onrender.com/api/getLotto/' + dateChk + '/' + inputLotto).then(res => {
                    setArrLotto(res.data.articles);
                    console.log(arrLotto);
                    setArrCheck(res.data.arr);
                    console.log(arrCheck);

                    setInputLength(false);

                    setStrSplit(inputLotto.split(''));
                    // setlastTwoNumber(strSplit[4] + strSplit[5]);



                }).catch(err => {
                    throw err.response.data;
                })

            } catch (e) {
                console.log(e);
            }
        }
    }


    const [arrRewardTwo, setArrRewardTwo] = useState(() => {
        let arr = [];

        for (let i = 8; i <= 12; i++) {
            arr.push(i);
        }
        return arr;
    });


    const [arrRewardThree, setArrRewardThree] = useState(() => {
        let arr = [];

        for (let i = 13; i <= 22; i++) {
            arr.push(i);
        }
        return arr;
    });

    const [arrRewardFour, setArrRewardFour] = useState(() => {
        let arr = [];

        for (let i = 23; i <= 72; i++) {
            arr.push(i);
        }
        return arr;
    });

    const [arrRewardFive, setArrRewardFive] = useState(() => {
        let arr = [];

        for (let i = 73; i <= 172; i++) {
            arr.push(i);
        }
        return arr;
    });

    const handleChangeInput = (e) => {
        setArrLotto([]);
        setInputLength(false);

        const re = /^[0-9\b]+$/; //rules
        console.log(e.target.value.length);

        if (e.target.value === "" || re.test(e.target.value)) {
            const limit = 6;
            // setInputLotto(e.target.value);
            setInputLotto(e.target.value.slice(0, limit));
        }

    }


    return (
        <>

            <Template >
                {/* <div className="container px-5 my-5"> */}

                <div className="row gx-5 justify-content-center">
                    <div className="col-lg-11 col-xl-9 col-xxl-8">

                        <div className="card shadow border-0 rounded-4 mb-5">
                            <div className="card-body p-5">

                                <div className="d-flex align-items-center mb-4">
                                    <div className="feature bg-primary bg-gradient-primary-to-secondary text-white rounded-3 me-3">
                                        <i className="bi bi-gift"></i>
                                    </div>
                                    <h3 className="fw-bolder mb-0">
                                        <span className="text-gradient d-inline">ผลสลากกินแบ่งรัฐบาล งวดวันที่ 16 ก.พ. 2567</span></h3>
                                </div>

                                <div className="row align-items-center gx-5">
                                    <div className="col text-center text-lg-start mb-4 mb-lg-0">
                                        <div className="bg-light p-4 rounded-4">
                                            <div className="text-primary text-start fw-bolder mb-2 h2">รางวัลที่ 1</div>
                                            <div className="text-gradient d-inline h3">  {arrLottoCurrent.length > 0 ? arrLottoCurrent[0].number : ''}</div>
                                        </div>
                                    </div>
                                    <div className="col text-center text-lg-start mb-4 mb-lg-0">
                                        <div className="bg-light p-4 rounded-4">
                                            <div className="text-primary text-start fw-bolder mb-2 h2">รางวัลเลขท้าย 2 ตัว</div>
                                            <div className="text-gradient d-inline h3"> {arrLottoCurrent.length > 0 ? arrLottoCurrent[5].number : ''}</div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row align-items-center gx-5 mt-4">
                                    <div className="col text-center text-lg-start mb-4 mb-lg-0">
                                        <div className="bg-light p-4 rounded-4">
                                            <div className="text-primary text-start fw-bolder mb-2 h2">รางวัลเลขหน้า 3 ตัว</div>
                                            <div className="text-gradient d-inline h3"> {arrLottoCurrent.length > 0 ? arrLottoCurrent[1].number : ''}
                                                <span className="ms-2"> {arrLottoCurrent.length > 0 ? arrLottoCurrent[2].number : ''}</span></div>
                                        </div>
                                    </div>
                                    <div className="col text-center text-lg-start mb-4 mb-lg-0">
                                        <div className="bg-light p-4 rounded-4">
                                            <div className="text-primary text-start fw-bolder mb-2 h2">รางวัลเลขท้าย 3 ตัว</div>
                                            <div className="text-gradient d-inline h3"> {arrLottoCurrent.length > 0 ? arrLottoCurrent[3].number : ''}
                                                <span className="ms-2"> {arrLottoCurrent.length > 0 ? arrLottoCurrent[4].number : ''}</span></div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>



                        <div className="card shadow border-0 rounded-4 mb-5">
                            <div className="card-body p-5">
                                <div className="row align-items-center gx-5">

                                    {/* {arrCheck.length}
                                    <p>{arrCheck.length > 0 ? arrCheck[0].order : 0}</p>
                                    <p>{arrCheck.length > 1 ? arrCheck[1].order : 0}</p> */}
                                    {/* <p>{arrCheck.length > 0 ? (arrCheck[0].order + ':' + arrCheck[0].number) : ''}</p>
                                    <p>{arrCheck.length > 1 ? (arrCheck[1].order + ':' + arrCheck[1].number) : ''}</p> */}


                                    {/* เช็คตำแหน่งเลยท้าย order=6*/}



                                    {arrLotto.length > 0 && inputLotto !== '' ?

                                        <div>
                                            <div className="d-flex align-items-center mb-4">
                                                <div className="feature bg-primary bg-gradient-primary-to-secondary text-white rounded-3 me-3">
                                                    <i className="bi bi-qr-code"></i>
                                                </div>
                                                <h3 className="fw-bolder mb-0">
                                                    <span className="text-gradient d-inline">ผลการตรวจสลากหมายเลข</span>
                                                </h3>
                                            </div>


                                            <div className="text-center">
                                                <h2 className="fw-bolder mb-0">
                                                    <span className="text-gradient d-inline mb-3">{inputLotto}</span>
                                                </h2>


                                                {arrCheck.length > 0 ? (arrCheck[0].order == 6 && (arrCheck[0].number == (strSplit[4] + strSplit[5])) ? 'ok' : 'no') : ''}



                                                {arrCheck.length > 0 ?

                                                    <div>
                                                        <div className="fs-5 fw-light text-success mb-3">
                                                            {/* {arrCheck[0].number } */}
                                                            {arrTextReward.map(item =>
                                                                <p>{item.value === (arrCheck.length > 0 ? arrCheck[0].order : 0) ? item.text : ''}</p>
                                                            )}

                                                            {arrRewardTwo.map(item =>
                                                                <p>{item === (arrCheck.length > 0 ? arrCheck[0].order : 0) ? textRewardTwo : ''}</p>
                                                            )}

                                                            {arrRewardThree.map(item =>
                                                                <p>{item === (arrCheck.length > 0 ? arrCheck[0].order : 0) ? textRewardThree : ''}</p>
                                                            )}

                                                            {arrRewardFour.map(item =>
                                                                <p>{item === (arrCheck.length > 0 ? arrCheck[0].order : 0) ? textRewardFour : ''}</p>
                                                            )}

                                                            {arrRewardFive.map(item =>
                                                                <p>{item === (arrCheck.length > 0 ? arrCheck[0].order : 0) ? textRewardFive : ''}</p>
                                                            )}

                                                        </div>
                                                        {arrCheck.length > 1 ?
                                                            <div className="fs-5 fw-light text-success">
                                                                <p>และ</p>
                                                            </div>
                                                            : ''}
                                                        <div className="fs-5 fw-light text-success mb-3">
                                                            {arrTextReward.map(item =>
                                                                <p>{item.value === (arrCheck.length > 1 ? arrCheck[1].order : 0) ? item.text : ''}</p>
                                                            )}
                                                        </div>
                                                        <div className="display-6 fw-bolder mb-3">
                                                            <span className="text-gradient d-inline"><i className="bi bi-emoji-heart-eyes me-2"></i>ยินดีด้วยค่ะ...คุณถูกรางวัล</span>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div>
                                                        <div className="fs-5 fw-light text-danger mb-2">ไม่ถูกรางวัล</div>
                                                        <div className="display-6 fw-bolder mb-3">
                                                            <span className="text-danger d-inline"><i className="bi bi-emoji-angry me-2"></i>คุณไม่ถูกรางวัลนะคะ...เสียใจด้วยค่ะ</span>
                                                        </div>
                                                    </div>
                                                }
                                                <div className="text-muted">
                                                    กรุณาตรวจเช็คตัวเลขสลากกินแบ่งที่กองสลากอีกครั้งนะคะ เพื่อความถูกต้อง
                                                </div>
                                            </div>

                                        </div>

                                        :


                                        <div className="d-flex align-items-center mb-4">
                                            <div className="feature bg-primary bg-gradient-primary-to-secondary text-white rounded-3 me-3">
                                                <i className="bi bi-qr-code"></i>
                                            </div>
                                            <h3 className="fw-bolder mb-0">
                                                <span className="text-gradient d-inline">ตรวจผลสลากกินแบ่งรัฐบาล</span>
                                            </h3>
                                        </div>
                                    }

                                    {inputLength === true ?
                                        <div className="text-center">
                                            <div class="fs-5 fw-light text-danger mb-2"> <i className="bi bi-emoji-dizzy"></i> กรุณากรอกเลขสลากให้ถูกต้องค่ะ</div>
                                        </div>

                                        : ''}


                                    <div className="card-body p-4">
                                        <div className="text-muted mb-2 ms-2">งวดวันที่ </div>
                                        <div className="col text-center text-lg-start mb-4 mb-lg-0">
                                            <select value={dateChk} onChange={(e) => setDateChk(e.target.value)}
                                                style={{ height: '50px' }} className="form-control mb-2">
                                                     <option value="16022567" selected >16 กุมภาพันธ์ 2567</option>
                                                <option value="01022567" >1 กุมภาพันธ์ 2567</option>
                                                <option value="17012567" >17 มกราคม 2567</option>
                                                <option value="30122566" >30 ธันวาคม 2566</option>
                                                <option value="16122566" >16 ธันวาคม 2566</option>
                                                <option value="01122566" >1 ธันวาคม 2566</option>
                                                <option value="16112566" >16 พฤศจิกายน 2566</option>
                                                <option value="01112566" >1 พฤศจิกายน 2566</option>
                                                <option value="16102566" >16 ตุลาคม 2566</option>
                                                <option value="01102566" >1 ตุลาคม 2566</option>
                                                <option value="16092566" >16 กันยายน 2566</option>
                                                <option value="01092566" >1 กันยายน 2566</option>
                                                <option value="16082566" >16 สิงหาคม 2566</option>
                                                <option value="31072566" >31 กรกฎาคม 2566</option>
                                                <option value="16072566" >16 กรกฎาคม 2566</option>
                                                <option value="01072566" >1 กรกฎาคม 2566</option>
                                                <option value="16062566" >16 มิถุนายน 2566</option>
                                                <option value="01062566" >1 มิถุนายน 2566</option>
                                                <option value="16052566" >16 พฤษภาคม 2566</option>
                                                <option value="02052566" >2 พฤษภาคม 2566</option>
                                                <option value="16042566" >16 เมษายน 2566</option>
                                                <option value="01042566" >1 เมษายน 2566</option>
                                                <option value="16032566" >16 มีนาคม 2566</option>
                                                <option value="01032566" >1 มีนาคม 2566</option>
                                                <option value="16022566" >16 กุมภาพันธ์ 2566</option>
                                                <option value="01022566" >1 กุมภาพันธ์ 2566</option>
                                                <option value="17012566" >17 มกราคม 2566</option>
                                                <option value="30122565" >30 ธันวาคม 2565</option>
                                                <option value="16122565" >16 ธันวาคม 2565</option>
                                                <option value="01122565" >1 ธันวาคม 2565</option>
                                                <option value="16112565" >16 พฤศจิกายน 2565</option>
                                                <option value="01112565" >1 พฤศจิกายน 2565</option>
                                                <option value="16102565" >16 ตุลาคม 2565</option>
                                                <option value="01102565" >1 ตุลาคม 2565</option>
                                                <option value="16092565" >16 กันยายน 2565</option>
                                                <option value="01092565" >1 กันยายน 2565</option>
                                                <option value="16082565" >16 สิงหาคม 2565</option>
                                                <option value="01082565" >1 สิงหาคม 2565</option>
                                                <option value="16072565" >16 กรกฎาคม 2565</option>
                                                <option value="01072565" >1 กรกฎาคม 2565</option>
                                                <option value="16062565" >16 มิถุนายน 2565</option>
                                                <option value="01062565" >1 มิถุนายน 2565</option>
                                                <option value="16052565" >16 พฤษภาคม 2565</option>
                                                <option value="02052565" >2 พฤษภาคม 2565</option>
                                                <option value="16042565" >16 เมษายน 2565</option>
                                                <option value="01042565" >1 เมษายน 2565</option>
                                                <option value="16032565" >16 มีนาคม 2565</option>
                                                <option value="01032565" >1 มีนาคม 2565</option>
                                                <option value="17022565" >17 กุมภาพันธ์ 2565</option>
                                                <option value="01022565" >1 กุมภาพันธ์ 2565</option>
                                                <option value="17012565" >17 มกราคม 2565</option>
                                                <option value="30122564" >30 ธันวาคม 2564</option>
                                                <option value="16122564" >16 ธันวาคม 2564</option>
                                                <option value="01122564" >1 ธันวาคม 2564</option>
                                            </select>
                                        </div>
                                        <div className="col text-center text-lg-start mb-4 mb-lg-0">
                                            <input value={inputLotto} onChange={handleChangeInput}
                                                style={{ height: '50px' }} placeholder="กรอกเลขสลากฯ ของคุณ" className="form-control mb-2" />
                                        </div>

                                        <div className="col text-center text-lg-end mb-4 mb-lg-0">
                                            <button onClick={handleCheckDate} className="btn btn-warning btn-lg px-5 py-3 me-sm-3 fs-6 fw-bolder mb-2">
                                                <i className="bi bi-search me-2"></i>

                                                {inputLotto === '' ? 'ตรวจสลากฯ ' + dateChk + ' ทั้งหมด' : 'ตรวจสลากฯ ของคุณ '}
                                            </button>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>

                        {arrLotto.length > 0 ?
                            <section>
                                <div className="card shadow border-0 rounded-4 mb-5">
                                    <div className="card-body p-5">

                                        <div className="mb-5">
                                            <div className="d-flex align-items-center mb-4">
                                                <div className="feature bg-primary bg-gradient-primary-to-secondary text-white rounded-3 me-3">
                                                    <i className="bi bi-gift"></i>
                                                </div>
                                                <h3 className="fw-bolder mb-0">
                                                    <span className="text-gradient d-inline">ผลสลากกินแบ่งรัฐบาล</span> </h3>
                                            </div>
                                            <div className="row row-cols-1 row-cols-md-4 mb-4">
                                                <div className="col">
                                                    <div className="bg-light p-4 rounded-4 mb-4 mb-lg-0">
                                                        <div className="text-secondary text-center">รางวัลที่ 1</div>
                                                        <div className="smalls text-muted text-center">รางวัลละ 6,000,000 บาท</div>
                                                        <div className="text-secondary text-center h4 mt-2">{arrLotto[0].number}</div>
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="bg-light p-4 rounded-4 mb-4 mb-lg-0">
                                                        <div className="text-secondary text-center">เลขหน้า 3 ตัว</div>
                                                        <div className="smalls text-muted text-center">2 รางวัลๆละ 4,000 บาท</div>
                                                        <div className="text-center h4 mt-2">{arrLotto[1].number} <span className="ms-2">{arrLotto[2].number} </span></div>
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="bg-light p-4 rounded-4 mb-4 mb-lg-0">
                                                        <div className="text-secondary text-center">เลขท้าย 3 ตัว</div>
                                                        <div className="smalls text-muted text-center">2 รางวัลๆละ 4,000 บาท</div>
                                                        <div className="text-center h4 mt-2">{arrLotto[3].number} <span className="ms-2">{arrLotto[4].number} </span></div>
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="bg-light p-4 rounded-4 mb-4 mb-lg-0">
                                                        <div className="text-secondary text-center">เลขท้าย 2 ตัว</div>
                                                        <div className="smalls text-muted text-center">1 รางวัลๆละ 2,000 บาท</div>
                                                        <div className="text-center h4 mt-2">{arrLotto[5].number} </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr />
                                            <div className="row row-cols-1 row-cols-md-3 mb-4">
                                                <div className="col">
                                                    <div className="mb-4 mb-lg-0">
                                                        <div className="text-secondary text-center">รางวัลข้างเคียงรางวัลที่ 1</div>
                                                        <div className="smalls text-muted text-center">2 รางวัลๆละ 100,000 บาท</div>
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="mb-4 mb-lg-0">
                                                        <div className="text-center h4 mt-2">{arrLotto[6].number}</div>
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="mb-4 mb-lg-0">
                                                        <div className="text-center h4 mt-2">{arrLotto[7].number}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr />
                                            <div>
                                                <div className="text-secondary text-center">ผลสลากกินแบ่งรัฐบาล รางวัลที่ 2 มี 5 รางวัลๆละ 200,000 บาท</div>
                                                <div className="row align-items-center gx-5">

                                                    {arrRewardTwo.length > 0 ? arrRewardTwo.map(item =>
                                                        <div className="col">
                                                            <div className="mb-4 mb-lg-0">
                                                                <div className="text-center h4 mt-2">{arrLotto[item].number}</div>
                                                            </div>
                                                        </div>
                                                    ) : ''}

                                                </div>
                                            </div>

                                            <hr />
                                            <div>
                                                <div className="text-secondary text-center">ผลสลากกินแบ่งรัฐบาล รางวัลที่ 3 มี 10 รางวัลๆละ 80,000 บาท</div>
                                                <div className="row align-items-center gx-56">

                                                    {arrRewardThree.length > 0 ? arrRewardThree.map(item =>
                                                        <div className="col">
                                                            <div className="mb-4 mb-lg-0">
                                                                <div className="text-center h4 mt-2">{arrLotto[item].number}</div>
                                                            </div>
                                                        </div>
                                                    ) : ''}

                                                </div>
                                            </div>


                                            <hr />
                                            <div>
                                                <div className="text-secondary text-center">ผลสลากกินแบ่งรัฐบาล รางวัลที่ 4 มี 50 รางวัลๆละ 40,000 บาท</div>
                                                <div className="row align-items-center gx-56">

                                                    {arrRewardFour.length > 0 ? arrRewardFour.map(item =>
                                                        <div className="col">
                                                            <div className="mb-4 mb-lg-0">
                                                                <div className="text-center mt-2 p-2">{arrLotto[item].number}</div>
                                                            </div>
                                                        </div>
                                                    ) : ''}

                                                </div>
                                            </div>

                                            <hr />
                                            <div>
                                                <div className="text-secondary text-center">ผลสลากกินแบ่งรัฐบาล รางวัลที่ 5 มี 100 รางวัลๆละ 20,000 บาท</div>
                                                <div className="row align-items-center gx-56">

                                                    {arrRewardFive.length > 0 ? arrRewardFive.map(item =>
                                                        <div className="col">
                                                            <div className="mb-4 mb-lg-0">
                                                                <div className="text-center mt-2 p-2">{arrLotto[item].number}</div>
                                                            </div>
                                                        </div>
                                                    ) : ''}

                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </section>
                            : ''}

                    </div>
                </div>
                {/* </div> */}

            </Template >
        </>


    )

}

export default Home;
