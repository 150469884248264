import Header from "./Header";
import Nav from "./Nav";
import Footer from "./Footer";
function Template(props) {
    return (
        <>
        <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-4082639713277934"
     crossorigin="anonymous"></script>
            <Nav />
            <Header />

            {props.children}

            <Footer />
        </>
    )
}

export default Template;
